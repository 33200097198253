import React, { useState } from 'react';
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby"
import FsLightbox from 'fslightbox-react';
import { Carousel } from 'react-bootstrap';
import { LightboxImage } from '../components/icon';


const PropertyGallery = (props) => {

    const data = useStaticQuery(graphql`
        query {
            propertyImage: file(relativePath: { eq: "property-detail.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            propertyImage2: file(relativePath: { eq: "property-detail2.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            propertyImage3: file(relativePath: { eq: "property-detail3.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const lightImages2 = props.propertyimages && props.propertyimages.map(img => img.url != undefined ? img.url : img.srcUrl);
    const imglength = lightImages2.length;

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1 
    });

    const openLighbox = (e) => {

        e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: 1
        });
    }

    const Slider = () => (
        <>
                
        <li>
                                <button className="btn-tour test" onClick={openLighbox}>
                                    <LightboxImage />
                                1/{imglength}</button>
                            </li>

</>

    )

    return (
        <div className="property-gallery">
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={lightImages2}
                type='image'
                slide={lightboxController.slide}
            />
            <Slider />
        </div>
    )
}

export default PropertyGallery;
