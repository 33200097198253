import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import Img from 'gatsby-image';
import { Link } from "gatsby";
import GetCTA from "./get-cta"
// import ScrollAnimation from 'react-animate-on-scroll';

const SectionArea = (props)=>{
  console.log("props",props);

  return (<React.Fragment>
       <section className="section-area landing-tiles-big">
       {/* <ScrollAnimation offset={100} animateIn="animate__fadeInUp" animateOnce={true} > */}

        <Container className="areaSec animate__animated">
          <Row className="align-items-lg-center">
          {props.data.map((Tile,key) => {
            return<>
            <Col lg="6" className={`col-xl-6  imgarea ${key==0?'biglandingone':''}`}>
              <div className="playing-video">
                {Tile.Tile_Block_Image &&
                  <Img objectFit="cover" fixed={Tile.Tile_Block_Image.childImageSharp.fixed} alt={`${Tile.Tile_Name}${Tile.Tile_Caption} - Orlando Reid`}/>     
                }
                <div className="btn-play tile-block">
                    <h2>{Tile.Tile_Name}</h2>
                    <span>{Tile.Tile_Caption}</span>
                    <div className="loc-details text-center">
                    <p>{Tile.Tile_Name && Tile.Tile_Name === "Riyadh Office" ?
                     <a href={`tel: 966 50 642 7322`}>966 50 642 7322 </a> 
                     : ""}</p>
                    <p>{Tile.Tile_Name && Tile.Tile_Name === "Nottingham" ?
                     <a href={`tel: 0115 822 7654`}>0115 822 7654</a> 
                     : ""}</p>
                     </div>
                    {Tile?.Tile_CTA_Link?.URL == 'home' &&
                    <Link class="btn btn-white" to="/">{Tile?.Tile_CTA_Label}</Link>
                    }
                    {Tile?.Tile_CTA_Link?.URL == 'manchester' &&
                    <Link class="btn btn-white" to={`/${Tile?.Tile_CTA_Link?.URL}/`}>{Tile.Tile_CTA_Label}</Link>
                    }
                    {Tile?.Tile_CTA_Link?.URL !='home' && Tile?.Tile_CTA_Link?.URL != 'manchester' &&
                    <GetCTA classname = "btn btn-white" link = {Tile.Tile_CTA_Link} label={Tile.Tile_CTA_Label}/>
                    }
                </div>          
              </div>
            </Col>
            </>
          })}
          </Row>
        </Container>
        {/* </ScrollAnimation> */}
      </section>
      
  </React.Fragment>)

}

export default SectionArea;
