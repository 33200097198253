import React, {useState, useEffect } from "react";
import { Container, Col, Row, Form } from 'react-bootstrap';
import Breadcrumbs from "../components/breadcrumbs";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown/with-html"
import ContactForm from "../components/forms/general-enquiry"
import OfficeContactForm from "../components/forms/office-contact"
import InvestorContactForm from "../components/forms/investor-enquiry"
import SalesForceForm from "../components/forms/salesforce-form"
import { useLocation } from "@reach/router"
import ReportForm from "../components/forms/property-report";
import { Logo } from '../components/icon';
import Img from "gatsby-image"
import $ from 'jquery'

function getParameterByName(name) {
  if (typeof window !== "undefined") {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}




const SINGLE_TEAM = gql`
query GetTeamDetails ($URL: String!){
  offices (where:{URL:$URL}) {
    URL
    Title
    Email
    Contact_Office_Intro
  }
}
`;

function TeamDetail(props) {
  var officeURL = getParameterByName('contactoffice');
  const location = useLocation();
  const currentUrl = location.pathname.replace(/^(.+?)\/*?$/, "$1");
  const investmentURL = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
  //console.log('investmentURL',investmentURL)
  useEffect(() => {
      setTimeout(function(){
        var PropertyReportcode = getParameterByName('postcode');

        if(PropertyReportcode != '' && PropertyReportcode != null) {

        $('.reportform #validationpostcode').val(PropertyReportcode);
      }

       }, 500);

    },[]);
const { loading, data } = useQuery(SINGLE_TEAM, {
    variables: { URL: officeURL }
  });
  if (loading) return (
    <div className="static-details gql-loading-screen">
        <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);

  return (
  <>
  <section className="section-contact team-details">
          <div className="contact-img contact-imgx animate__animated">
          <Img fluid={props.FormBG} />            
          </div>
          <Container>
            <Row className="justify-content-xl-end">
              <Col className="col-xl-6 textContact animate__animated ">
            {officeURL !=null ?
              <Breadcrumbs alias='our-offices'/> : <Breadcrumbs/>
            }

                <div className="contact-text team-info">
                  <Form className="apply-job">
        <Container>
          <Row>
            <Col lg={11}>
            {officeURL !=null &&
              <h1>
                Contact {data.offices[0]?.Title}
              </h1>
            }
            {officeURL ==null &&
              <h1>
                {props.Title}
              </h1>
            }
            {officeURL ==null &&
              <ReactMarkdown source={props.FormIntro} escapeHtml={false}/>
            }
            {/* {JSON.stringify(data.offices)} */}
            {officeURL !=null &&
              <>
            {data.offices.map((office) => {
              return<>
                <ReactMarkdown source={office.Contact_Office_Intro} escapeHtml={false}/>
                {office.Title.toLowerCase() == "manchester" ?
                 <SalesForceForm propertyName={"Contact Manchester"} retURL={process.env.GATSBY_SITE_URL+"/thank-you-manchester-office-contact"}/>
                :
                  <OfficeContactForm to_email_id={office.Email} office_name={office.Title} />   
                }
               
              </>
              })}
              </>             
            }
            
            {/* {officeURL ==null && props.formname !="Property_Report_Form" && investmentURL != "investment-enquiry" &&
              <ContactForm />   
            }  */}
            {(officeURL ==null) &&
              props.formname =="Nottingham_Investment_Enquiry" && investmentURL == "nottingham-investment-enquiry" ?
              <InvestorContactForm to_email_id="armondg@orlandoreid.co.uk"/> :  <ContactForm />   
            }
            
            {officeURL ==null && investmentURL =="investment-enquiry" &&
              //  <InvestorContactForm />
              <SalesForceForm propertyName={"Investment Enquiry"} retURL={process.env.GATSBY_SITE_URL+"/thank-you-investment-inquiry"}/>
            }
            {officeURL ==null && props.formname =="Property_Report_Form" &&
              <ReportForm />   
            }
            </Col>
          </Row>
        </Container>
      </Form>
                </div>
              </Col>
            </Row>
          </Container>
  </section>     
  </>
)
}
export default TeamDetail

