import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import Header2 from "../components/Header2/header2";
import Footer from "../components/Footer/footer";
// import '../scss/pages/details.scss';
import ImageSlider2 from '../components/ImageSlider2/imageSlider2';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import Propertymap from "../components/property-map";
import ReactMarkdown from "react-markdown/with-html"
import GetArea from "../components/get-area-property";
import PropertyGallery from "../components/property-gallery";
import Floorplan from "../components/floorplan";
import { Logo } from "../components/icon/icon";
import GetTeam from "../components/get-team"
import {
	isMobile
  } from "react-device-detect";
import GetOfficeBlock from '../components/getOfficeBlockDetails'
import SocialShare from "../components/share";
import BookingModal from "../components/book-view-modal";
import $ from 'jquery'
import axios from 'axios';
import { useLocation } from "@reach/router"
import SEO from "../components/Seo/seo";
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import { AreaGuideArrow, VideoPlayButton, BtnPlay, Refresh, Bedroom, Bathroom, Reception, Kitchen } from '../components/icon';
import Modal from 'react-bootstrap/Modal'
// import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import NotFoundPage from "../pages/404";
import { isArray } from "@apollo/client/cache/inmemory/helpers";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){
    properties(where:{id: $id}) {
      id
      title
      office_crm_id
      price
      price_qualifier
      images
      rent
      building
	  accomadation_summary
      address
      brochure
      search_type
      display_address
      room_details
	  bedroom
	  bathroom
	  reception
	  kitchen
      description
      long_description
      crm_negotiator_id
      floorplan
      epc
      virtual_tour
      latitude
      longitude
	  imagetransforms
	  department
	  selling_info
	  publish
	  city
	  extra
		crm_provider
		letting_info
    }
  }
`;

function gotohash(event) {
	var thishash = event;
	$('html, body').animate({
		scrollTop: $(thishash).offset().top - 120
	}, 1000);
}


function PropertyDetails(props) {
	const [show, setShow] = useState(false);
	const [showvideo, setShowVideo] = useState(false);

	const [showlife, setShowlife] = useState(true);

	const Modules = props.Modules
	const location = useLocation();
	const propertyUrl = location.href
	const [] = useState(false);
	const [propItems, setPropItems] = useState([])
	const getitems = async url => {
		try {
			const { data } = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
				  } 
			})
			if (data && data.length > 0) {
				setPropItems(data)
			}
		} catch (error) {
			console.error(error)
		}
	}
	const routeChange = () => {
		if (typeof window !== "undefined") {
			window.history.back();
		}
	};
	useEffect(() => {
		setTimeout(function(){
		var checkarea = $('.property-details .property_areaguide').length;
		if(checkarea == 0) {
			setShowlife(false)
		} else {
			setShowlife(true)
		}
	}, 1500);
		$(".openbooking").on("click", function () {
			$('.triggerbooking').click();
		});
		let url = process.env.GATSBY_STRAPI_SRC + "/stb-lists/item/Property-details?pid=" + props.PropertyID;
		getitems(url)
		window.addEventListener('scroll', handleScroll, true);
		function handleScroll() {
			let element = document.querySelector(".details-banner");
			if (element) {
				let reactElement = element.getBoundingClientRect().bottom
				if (reactElement - 100 > 0) {
					document.querySelector(".secondary-nav").classList.remove("secondary-nav-fixed")
				} else {
					document.querySelector(".secondary-nav").classList.add("secondary-nav-fixed")
				}
			}
		}
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}


	}, []);


	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});



	const GlobalConfig = props.GlobalConfig




	const { loading, data } = useQuery(PROPERTY_DETAILS, {
		variables: { id: props.PropertyID }
	});
	if (loading) return (
		<div className="static-details gql-loading-screen">
			<Container>
				<Logo />
				<div className="gql-loading">Loading ...</div>
			</Container>
		</div>);

$('.secondary-nav li').click(function() {
    $(this).addClass('active').siblings().removeClass('active');
});
	if(data && data?.properties[0]?.publish == false) {
		return <NotFoundPage />
	}
	return (
		<div className="sub-wrapper property-details">
			{/* header start */}
			<Header2 />
			{/* header end */}
			{/* content start */}
			<div className="content">
				{data?.properties?.map((property) => {

					let expiry = property?.selling_info?.tenure?.expiry
					const year = new Date(expiry).getFullYear()
					let remainingExpiryYear = year - new Date().getFullYear()

					let processedImages = JSON.stringify({});
					if (property?.imagetransforms?.images_Transforms) {
						processedImages = property.imagetransforms.images_Transforms;
					}
					if (property.search_type == "lettings") {
						var price = Math.round(property.price)
						var searchtype = 'to rent';
					} else {
						price = Math.round(property.price)
						searchtype = 'for sale';
					}
					const metaTitle = property.title + ' in ' + property.display_address
					const metaDesc = 'Book a viewing for this ' + property.title + ' in ' + property.display_address + ' with Orlando Reid at ' + property.price + '. Contact Orlando Reid, and get assisted for all your property needs.'

					var fullDESC = property.long_description
					fullDESC = fullDESC.replace(/FULL/gi, "");
					fullDESC = fullDESC.replace(/DESCRIPTION/gi, "");
					var VTour = '', BrochureURL = '', PropVideo ='';
					//var regex = /^(https?:\/\/[^/]+(\/[\w-]+)+)/;
					//var url = property.virtual_tour.match(regex)[0];
					
					if(property.virtual_tour !=null && property.virtual_tour!= undefined) {
						if (Array.isArray(property.virtual_tour)) {
							VTour = property.virtual_tour[0];
							 
						}
						// if (property.virtual_tour && Object.keys(property.virtual_tour).length > 0) {
						// 	VTour = (property.virtual_tour).value;
						// }
						// if( Object.keys(property.virtual_tour).length > 0 && property.virtual_tour && (property.virtual_tour).length >= 2){
						// 	VTour = (property.virtual_tour)[0].value
						// }
						if(Object.keys(property.virtual_tour).length > 0 && property.virtual_tour && (property.virtual_tour).length >= 2){
							PropVideo = (property.virtual_tour)[1].value
						}
					}
					if (property.brochure && Object.keys(property.brochure).length > 0) {
						BrochureURL = property.brochure[0].srcUrl;
					}

					//console.log('vvvvvvvvvvvv',VTour,PropVideo)

					var acc_summ = property.accomadation_summary && property.accomadation_summary;

					var backURL = ''
					if(property.department=='residential' && property.search_type == 'sales' && props.resultspage=='salespage') {
						backURL = '/property/for-sale/in-london-and-manchester/'
					}
					if(property.department=='residential' && property.search_type == 'lettings' && props.resultspage=='rentpage'){
						backURL = '/property/to-rent/in-london-and-manchester/'
					}
					if(property.department=='new_homes' && property.search_type == 'sales' && props.resultspage=='newsalespage') {
						backURL = '/property/new-homes/for-sale/in-london/'
					}

					if(property.department=='new_homes' && property.search_type == 'sales' && props.resultspage=='salespage') {
						backURL = '/property/for-sale/in-london-and-manchester/'
					}
					if(property.department=='new_homes' && property.search_type == 'lettings' && props.resultspage=='rentpage') {
						backURL = '/property/to-rent/in-london-and-manchester/'
					}
					if(property.department=='new_homes' && property.search_type == 'lettings' && props.resultspage=='newrentpage') {
						backURL = '/property/new-homes/to-rent/in-london/'
					}
					if(property.department=='help_to_buy' && property.search_type == 'sales' && props.resultspage=='helppage') {
						backURL = '/property/help-to-buy/in-london/'
					}

					return <>
						{/* section banner start */}
						<SEO title={metaTitle} description={metaDesc} />

                        <Helmet>
                            <script type="application/ld+json">{`{
                                "@context" : "http://schema.org",
                                "@type" : "Product",
                                "name" : "[${metaTitle}]",
                                "image" : "[${property.images[0].url}]",
                                "description" : "[${metaDesc}]",
                                "brand" : {
                                    "@type" : "Organization",
                                    "name" : "Orlando Reidl",
                                    "logo" : "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/OR_Logo_115effa5b8.jpg"
                                },
                                "offers": {
                                    "@type": "Offer",
                                    "url": "[${propertyUrl}]",
                                    "priceCurrency": "GBP",
                                    "price": "[${price}]",
                                    "availability": "https://schema.org/InStock"
                                }
                            }`}</script>
                        </Helmet>

						<section className="details-banner">
							<div className="detail-img">

								<ImageTransform imagesources={property.images[0].url} renderer="srcSet" imagename="property.images.detail" attr={{ alt: property.display_address + ' - Orlando Reid' }}
									imagetransformresult={processedImages} id={property.id} testparam={true} />

								<ul className="action-list d-flex">
									{VTour != null && VTour != '' &&
										<li>
											<a href={VTour}  target="_blank">
												<button className="btn-tour d-none">
													<AreaGuideArrow /> tour</button>
											</a>
										</li>
									}

									{PropVideo != null && PropVideo !='' &&
										<li>
											<a href={PropVideo} target="_blank">
												<button className="btn-tour btn-detail-play">
													<BtnPlay />Video
												</button>
											</a>
											<Modal
												show={showvideo}
												size="lg"
												centered={true}
												onHide={() => setShowVideo(false)}
												dialogClassName="modal-90w modal-form modal-tour"
												aria-labelledby="example-custom-modal-styling-title"
											>
												<Modal.Header closeButton>
												</Modal.Header>
												<Modal.Body>
												<iframe width="100%" height="500px" src={PropVideo}/>
												</Modal.Body>
											</Modal>
										</li>
									}
									{VTour != null && VTour != '' &&
										<li>
											<a href="javascript:;" onClick={() => setShow(true)}>
												<button className="btn-tour">
													<Refresh />360
												</button>
											</a>
											<Modal
												show={show}
												size="lg"
												centered={true}
												onHide={() => setShow(false)}
												dialogClassName="modal-90w modal-form modal-tour"
												aria-labelledby="example-custom-modal-styling-title"
											>
												<Modal.Header closeButton>
												</Modal.Header>
												<Modal.Body>
												<iframe width="100%" height="500px" src={VTour}/>
												</Modal.Body>
											</Modal>
										</li>
									}
									<PropertyGallery propertyimages={property.images} />

								</ul>
							</div>
							<Container className="d-xl-flex justify-content-xl-end">
								<div className="details-text">
									<Link className="back-link" to={`${backURL}`}>Back to Property Listings </Link>
									<address className="location-addrs">
										<h1>{property.title} in {property.display_address}</h1>
										<span className="price-info">£{price.toLocaleString()}</span>
										<getPrice />
										<div className='room-list'>
											{property.bedroom !=null && property.bedroom > 0 &&
											<div className="room-icons">
												<Bedroom/>
												<span>{property.bedroom} {property.bedroom > 1 ? 'Bedrooms' : 'Bedroom'}</span>
											</div>
											 }
											 {property.bathroom !=null && property.bathroom > 0 &&
											<div className="room-icons">
												<Bathroom/>
												<span>{property.bathroom} {property.bathroom > 1 ? 'Bathrooms' : 'Bathroom'}</span>
											</div>
											}
											{property.reception !=null && property.reception > 0 &&
											<div className="room-icons">
												<Reception/>
												<span>{property.reception} {property.reception > 1 ? 'Receptions' : 'Reception'}</span>
											</div>
											}
											{property.kitchen !=null && property.kitchen > 0 &&
											<div className="room-icons">
												<Kitchen/>
												<span>{property.kitchen} {property.kitchen > 1 ? 'Kitchens' : 'Kitchen'}</span>
											</div>
											}
										</div>
										<div className="btn-wrap d-none d-xl-block">
											<BookingModal propertyurl={propertyUrl} officecrmid={property.office_crm_id} displayAddress={property.display_address} city={property.city} searchType={property.search_type} />
										</div>

										<SocialShare sharepage="property" Title="Share this Property" address_details={property.display_address} id={property.id} title={property.display_address} price={property.price} type={property.title} telephone={property.crm_negotiator_id?.Telephone} images={property.images[0]} bedrooms={property.bedroom} bathrooms={property.bathroom} data={property} />

									</address>
									{Modules && Modules.map((Modules, i) => {
										return<>
										            {Modules.Select_Team_Members !=null &&
													<GetTeam propertyurl={propertyUrl} negostaff={Modules.Select_Team_Members} officecrmid={property.office_crm_id} crm_negotiator_id={property.crm_negotiator_id} crm_provider={property.crm_provider} search_type={property.search_type} 
													displayAddress={property.display_address}  city={property.city} searchType={property.search_type}/>
													}
													</>
									})}
									<div className="office_detailBalock">
									{
										!isMobile && property.office_crm_id && <div>
											<GetOfficeBlock id={property.office_crm_id} />
										</div>
									}
									</div>
								</div>
							</Container>
						</section>
						{/* section banner end */}
						{/* section text start */}
						<section className="section-text">
							<nav className="secondary-nav">
								<Container>
									<ul className="d-flex">
										<li className="active">
											<a onClick={() => gotohash('#description-section')} href="javascript:void(0)">description</a>
										</li>
										<li>
											<a onClick={() => gotohash('#map-section')} href="javascript:void(0)">map</a>
										</li>
										<li>
											<a onClick={() => gotohash('#map-section')} href="javascript:void(0)">schools</a>
										</li>
										{property.floorplan.length > 0 && property.floorplan &&
											<Floorplan floorplan={property.floorplan} />
										}
										{BrochureURL &&
											<li>
												<a href={BrochureURL} target="_blank">brochure</a>
											</li>
										}
										{showlife &&
										<li className="scrolllocalarea">
											<a onClick={() => gotohash('#local-life')} href="javascript:void(0)">local life</a>
										</li>
										}
									</ul>
									<BookingModal propertyurl={propertyUrl} officecrmid={property.office_crm_id}  displayAddress={property.display_address} city={property.city} searchType={property.search_type}/>
								</Container>
							</nav>
							<Container id="description-section">
							{/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
								<Row>
									<Col xl={4}>
										<ul>
											{
												acc_summ && acc_summ.map((item,index)=> {
													if(item) {
														return(
															<li>{item}</li>
														)
													}

												})
											}
										</ul>

									</Col>
									<Col xl={8}>
									<Row className="further-details">
										{property.search_type == "sales" && property?.crm_provider == "reapit" && 
											<>			
												<>
													<Col className="column" lg={6}>
														<ul>
															<li>
																<strong>Tenure:</strong>
																<p>{property?.selling_info?.tenure?.type ? property?.selling_info?.tenure?.type : "Not Available"}</p>
															</li>
															<li>
																<strong>Annual ground rent:</strong>
																<p>{property?.extra?.groundRent ? `£${property?.extra?.groundRent}` : "Not Available"}</p>
															</li>
															<li>
																<strong>Annual service charge amount:</strong>
																<p>{property?.extra?.serviceCharge ? `£${Math.round(property?.extra?.serviceCharge)}` : "Not Available"}</p>
															</li>
															<li>
																<strong>Council tax band:</strong>
																<p>{property?.extra?.councilTax ? property?.extra?.councilTax : "Not Available"}</p>
															</li>
														</ul>
													</Col>
													<Col className="column" lg={6}>
														<ul>
															<li>
																<strong>Length of lease:</strong>
																<p>{`${remainingExpiryYear ? remainingExpiryYear : "Not Available"} ${remainingExpiryYear <= 1 ? "year remaining" : "years remaining"}`} </p>
															</li>
															<li>
																<strong>Ground rent review period:</strong>
																<p>{property?.extra?.groundRentReviewDate ? property?.extra?.groundRentReviewDate : "Not Available"}</p>
															</li>
															<li>
																<strong>Shared ownership:</strong>
																<p>{property?.selling_info?.sharedOwnership?.sharedPercentage ? property?.selling_info?.sharedOwnership?.sharedPercentage : "Not Available"}</p>
															</li>
														</ul>
													</Col>
													</>
												</>
											}
										{property.search_type == "sales" && property?.crm_provider == "vebra" &&
											<Col className="column" lg={6}>
												<ul>
													<li>
														<strong>Tenure:</strong>
														<p>{property?.extra?.propertyTenure ? property?.extra?.propertyTenure : "Not Available"}</p>
													</li>
												</ul>
											</Col>
										}
										{property.search_type == "lettings" && property?.crm_provider == "reapit" &&
										<>
											<Col className="column" lg={6}>
												<ul>
													<li>
														<strong>Deposit:</strong>
														<p>{property?.letting_info?.deposit?.amount ? property?.letting_info?.deposit?.amount : "Not Available"}</p>
													</li>
													<li>
														<strong>Council tax band:</strong>
														<p>{property?.extra?.councilTax ? property?.extra?.councilTax : "Not Available"}</p>
													</li>
												</ul>
											</Col>
											<Col className="column" lg={6}>
												<ul>
													<li>
														<strong>Length of tenancy:</strong>
														<p>{"Not Available"}</p>
													</li>
												</ul>
											</Col>
										</>
										}
										</Row>
										<ReactMarkdown source={fullDESC?fullDESC:property.description} escapeHtml={false} />

									</Col>
								</Row>
								{/* </ScrollAnimation> */}

							</Container>
							<div className="office_detailBalock">
							<Container>
							{
								isMobile && property.office_crm_id && <div>
										<GetOfficeBlock id={property.office_crm_id} />
									</div>
							}
							</Container>
							</div>

						</section>
						{/* section text end */}
						{/* section map start */}
						<div className="full-map-area" id="map-section">
							<Propertymap data={property} Latitude={property.latitude} Longitude={property.longitude} />
						</div>
						{/* section map end */}
						{/* section explore start */}
						<div id="local-life">
							<GetArea postcode={property.address.postcode && property.address.postcode.split(" ")[0]} officecrmid={property.office_crm_id} />
						</div>
						{/* section explore end */}
						{/* similar properties start */}
						{propItems && propItems.length > 0 &&
						<section className="section-properties similar-properties">
							<Container className="properties">
							{/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
								<div className="head-sec text-center">
									{propItems && propItems.length > 0 ? <h2>Similar Properties {searchtype} in {property.address.area ? property.address.area + ', ' : ""} {property.address.town}</h2>
										: ''}

								</div>
								{propItems && propItems.length > 0 ? <ImageSlider2 data={propItems.length > 2 ? propItems.slice(0, 3) : propItems} Title={`Similar Properties in ${property.address.address1}, London`} /> : ''}

							{/* </ScrollAnimation> */}
							</Container>
						</section>
						}
						{/* similar properties end */}
					</>
				})}

			</div>
			{/* content end */}
			{/* footer start */}

			<Footer popularSearch='propertydetails' propertydetails={data?.properties} footerDetails={GlobalConfig}  propertyaddress={data?.properties[0]?.address?.town}/>

			{/* footer end */}

		</div>
	)

}

export default PropertyDetails
